



































import { Component, Prop, Vue } from "vue-property-decorator";
import GA from "@/app/modules/_global/classes/Analytics";

type Tile = {
  name: string;
  contentable: {
    image?: string;
  };
};

@Component({
  components: {
    FormBuilder: () => import("@/app/modules/service/builder/FormBuilder.vue")
  }
})
export default class ServiceComponent extends Vue {
  @Prop(Object) readonly tile!: Tile;

  constructor() {
    super();

    GA.addCustomDimension(1, `${this.userData?.user.id}`);
    GA.trackView(`Service: ${this.tile?.name}`);
  }

  get userData() {
    return this.$store.getters["profile/data"];
  }

  addInfoToProfile() {
    this.$router.push({
      name: "profile",
      query: {
        redirect: this.$route.path
      }
    });
  }
}
